/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "../node_modules/flag-icons/css/flag-icons.min.css";



// @font-face {
//     font-family: 'Nunito';
//     src: url("./assets/fonts/Nunito-VariableFont_wght.ttf");
// }

@font-face {
    font-family: 'Nunito Sans';
    font-weight: 400;
    src: url("./assets/fonts/NunitoSans-Regular.ttf");
}

@font-face {
    font-family: 'Nunito Sans';
    font-weight: 600;
    src: url("./assets/fonts/NunitoSans-SemiBold.ttf");
}

@font-face {
    font-family: 'Nunito Sans';
    font-weight: 800;
    src: url("./assets/fonts/NunitoSans-ExtraBold.ttf");
}

// *{
//     font-family: 'Nunito';
// }

.text-center {
    text-align: center;
}

.d-none {
    display: none !important;
}

.bar-notification-icon {
    width: 50px;
    font-size: 24px;
}

ion-header::after {
    background-image: none !important;
}

ion-header {
    box-shadow: inset 0px -1px 0px #ebeef7 !important;
}

ion-toolbar {
    --background: transparent;
    color: inherit !important;
}

.main-toast {
    // height: calc(100% - 58px) !important;
}

/* HEADER */

.notifications-icon-wrapper {
    position: relative;
    width: 50px;

    span {
        position: absolute;
        top: 2px;
        right: 13px;
        width: 7px;
        height: 7px;
        display: inline-block;
        z-index: 99;
        border-radius: 50%;
        border: 2px solid #FFFFFF;
        box-sizing: content-box;
    }
}

/* HEADER */

/* CUSTOM INPUT */



.ionic-selectable {
    border: 1px solid #EBEEF7;
    border-radius: 5px;
    padding: 0 16px;
    height: 48px;

    .fi {
        margin-right: 4px;
    }

    .country-name {
        text-transform: capitalize !important;
    }
}


.input-wrapper {
    width: 100%;
    border: 1px solid #EBEEF7;
    border-radius: 5px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;

    &.invalid {
        border-color: var(--ion-color-danger500);
    }

    &.text {
        margin-bottom: 16px;
    }

    input {
        border: none;
        width: calc(100% - 28px);

        &:focus {
            outline-width: 0;
        }

        &::placeholder {
            color: var(--ion-color-gray400);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

    }

    .eye-icon {
        width: 24px;
        height: 24px;

        ion-icon {
            font-size: 24px;
        }
    }
}

/* CUSTOM INPUT END */

/** BUTTONS **/

.custom-btn {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;

    &.custom-primary-btn {
        --background: var(--ion-color-primary500);
        margin-top: 32px;
        margin-bottom: 12px;
    }



    .btn-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;
        text-transform: capitalize !important;
        display: inline-block;
        margin-right: 8px;
    }
}

/** BUTTONS END **/

/** SELECT **/

.custom-select {
    width: 100%;
    border: 1px solid #EBEEF7;
    border-radius: 5px;
    height: 48px;
    margin-bottom: 12px;

    &::part(icon) {
        margin-right: 12px;
    }

    &::part(placeholder) {
        opacity: 1 !important;
        color: var(--ion-color-gray500);
    }
}


/** SELECT END **/

/** AUTH **/



.auth-btn {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;

    &.auth-primary-btn {
        --background: var(--ion-color-primary500);
        margin-top: 32px;
        margin-bottom: 12px;
    }

    &.auth-secondary-btn {
        --background: var(--ion-color-primary50) !important;
        color: var(--ion-color-primary500) !important;
        --box-shadow: none !important;
    }

    .auth-btn-text {
        font-weight: 700;
        font-size: 16px;
        line-height: 48px;
        text-transform: capitalize !important;
        display: inline-block;
        margin-right: 8px;

        &.no-transform {
            text-transform: none !important;
        }
    }
}

.outer-auth-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    // height: 100%;

    .auth-input-errror {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--ion-color-danger500);
        position: absolute;
        left: 0;
        bottom: -23px;
    }

    .auth-wrapper {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 24px;

        .auth-title {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;

            .auth-title-icon {
                width: 28px;
                height: 28px;
                margin-right: 8px;

                ion-icon {
                    font-size: 28px;
                }
            }
        }

        .auth-subtitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 12px;

            p {
                margin: 0;
            }
        }

        .auth-form {
            width: 100%;
            margin-top: 32px;
        }

        .auth-actions {
            display: flex;
            width: 100%;
            flex-direction: column;
            position: relative;

            .action-below-input {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                font-size: 14px;
                font-weight: 600px;
                line-height: 20px;

                .first-action {
                    margin-right: 16px;
                }
            }
        }

        .auth-options {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: 24px;

            .option {
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--ion-color-gray500);

                .option-label {
                    margin: 0;
                    margin-bottom: 12px;

                    .option-action {
                        font-weight: 600;
                        color: var(--ion-color-gray900);
                    }
                }
            }
        }
    }
}

/** AUTH END **/

.custom-modal {
    &::part(content) {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .modal-title {
        color: var(--ion-color-gray900);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .modal-loading-spinner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
    }
}



.section-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: var(--ion-color-gray900);
    margin: 0;
    margin-bottom: 20px;
}

/* ION SELECTABLE BUTTON FIX */

.selectable {
    position: relative;

    .ionic-selectable-cover {
        position: absolute;
        height: 46px;
    }
}

.customSelectModal {

    ion-content {
        &::part(scroll) {
            padding: 0 !important;
        }

        ion-item {
            border-style: none !important;
            --border-width: 0 0 0 0 !important;
            --border: none !important;

            &::part(native) {
                --padding-start: 0;
            }

            // &::part(native) {
            //     border-bottom: 1px solid var(--ion-color-gray500);
            //     .item-inner {
            //         // border-style: none !important;
            //         background-color: red !important;
            //     }
            // } .item-inner {
            //     // border-style: none !important;
            //     background-color: red !important;
            // }
        }
    }

    .selectable-navbar {
        display: flex;
        padding: 10px 8px;

        .title {
            padding: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
            color: var(--ion-color-gray900);
        }
    }

    ion-footer {
        height: 64px;
        padding: 8px;
    }

    .selectable-footer {
        position: relative;
        top: -32px;
    }

    .no-items {
        padding: 8px;
    }
}

/* ION SELECTABLE BUTTON FIX END */



.form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .label {
        margin: 0;
        text-transform: capitalize;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: var(--ion-color-gray900);

        .optional {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: var(--ion-color-gray500);
            text-transform: lowercase;
        }
    }
}

.back-icon {
    color: var(--ion-color-gray900);
}

/* BUTTONS */

.btn {
    margin: 0;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    --background: var(--ion-color-primary500);

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    text-transform: capitalize;

    &.primary-btn {
        --background: var(--ion-color-primary500);
        --color: #ffffff !important;
    }

    &.gray-outline-btn {
        border: 1px solid var(--ion-color-gray50);
        --color: var(--ion-color-gray500);
        --background: #ffffff;
    }

}
/* BUTTONS END*/



.social-login-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 8px 0;

    .social-login-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--ion-color-gray500);
        text-align: center;
        margin-bottom: 8px;
    }

    .social-login-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;

        .social-login-button {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            --border-radius: 50%;
            --padding-bottom: 0 !important;
            --padding-end: 0 !important;
            --padding-start: 0 !important;
            --padding-top: 0 !important;
            --background: transparent;
            --border-color: transparent;
            --border-width: 0;

            &:not(.ion-activated){
                --box-shadow: none;
            }
        }
    }
}

// ACTION SHEET
ion-action-sheet {
  .action-sheet-title.sc-ion-action-sheet-md {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--ion-color-gray900);
  }

  .action-sheet-group.sc-ion-action-sheet-md:last-child {
    border-radius: 16px 16px 0 0;
  }
}

// CARDS

ion-card {
  border: 1px solid var(--ion-color-gray50);
  border-radius: 8px;
  box-shadow: var(--ion-color-gray900), 7%;
}
